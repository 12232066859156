<template>
	<div id="login">
		<div class="col-12 col-md-4 lateral" elevation="2">
			<Logo/>
			<v-form ref="form_usuario">
				<div class="row">
					<div class="col-12">
						<h2>
							Olá! Seja bem-vindo(a)
						</h2>
						<h3>
							Por favor digite a nova senha.
						</h3>
					</div>
				</div>
				<div class="row">
					<div class="col-12">
						<v-text-field type="password" :rules="[v => !!v || 'Campo obrigatório']" v-model="inputs.senha" label="Senha" placeholder="Senha" outlined/>
					</div>
					<div class="col-12">
						<v-text-field type="password" :rules="[v => (v == inputs.senha) || 'Senhas não são iguais']" v-model="inputs.repeteSenha" label="Repetir senha" placeholder="Repetir Senha" outlined/>
					</div>
				</div>
				<div class="row">
					<div class=" offset-6 voltar">
						<router-link to="/login">Voltar</router-link>
					</div>
					<div class="col-12">
						<v-btn type="submit" block elevation="2" large color="primary" :loading="loading" >
							Enviar
						</v-btn>
					</div>
				</div>
			</v-form>
		</div>
		<div class="col-8 imagem">
			<LateralLogin/>
		</div>
		<Loader v-if="loading"/>
	</div>
</template>

<script>
	import Logo from "@/components/Logo.vue"
	import LateralLogin from "@/components/LateralLogin.vue"
	import Loader from "@/components/Loader.vue"
	import store from '@/store'
	
	export default {
		name: 'NovaSenha',
		components: {
			Logo,
			LateralLogin,
			Loader
		},
		data() {
            return {
				loading: false,
                inputs: {
                    senha: '',
					repeteSenha: '',
                },
                validacao: {
                    senha: false,
					repeteSenha: false,
                }
            }
        },
        methods: {
			changeLoading(value) {
				this.loading = value
			},
			checkFormValidity() {
				this.validacao.senha = (this.inputs.senha == '') ? true : (this.inputs.senha.length < 8 ? true : false)
				this.validacao.repeteSenha = (this.inputs.repeteSenha != this.inputs.senha)  ? true : false
				const valid = this.validacao.senha || this.validacao.repeteSenha
				return !valid
			},
			handleSubmit() {
				this.changeLoading(true)
                if (!this.checkFormValidity()) {
					this.changeLoading(false)
					return
				}else{
					store.dispatch('recuperarSenhaNovaSenha', this.inputs).then(()=>{
						// if (!resp.sucesso && resp.sucesso != undefined) {
						// 	if (resp.error == 'Usuário inexistente') {
						// 		this.validacao.usuario = true
						// 	}else if (resp.error == 'Senha incorreta') {
						// 		this.validacao.senha = true
						// 	}else{
						// 		this.$swal({
						// 			title: 'Atenção',
						// 			icon:'warning',
						// 			text: 'Ocorreu algum erro. Por favor tente novamente.',
						// 			showCancelButton: false,
						// 			showCloseButton: false,
						// 			showConfirmButton: true,
						// 			confirmButtonText:'Ok'
						// 		})
						// 	}
						// }
						this.changeLoading(false)
					})
                }
            }
        }
	}
</script>
<style lang="scss">
	#login{
		flex: 1;
		display: flex;
		max-height: 100vh;
		.lateral{
			form{
				padding: 50px;
				h2{					
					font-style: normal;
					font-weight: 500;
					font-size: 22px;
					line-height: 36px;
					color: $primarycolor;
				}
				.lembrar-usuario{
					display: flex;
					align-items: center;
					label{
						display: flex;
						align-items: center;
						margin-bottom: 0;
						p{
							padding: 0px;
							font-style: normal;
							font-weight: normal;
							font-size: 14px;
							line-height: 17px;
							color: #373737;
						}
						input{
							width: auto;
							margin-right: 10px;
						}
					}
				}
				.recuperar-senha{
					display: flex;
					align-items: center;
					justify-content: flex-end;
					text-align: right;
					a{
						text-align: right;
						font-style: normal;
						font-weight: normal;
						font-size: 16px;
						line-height: 20px;
						color: rgba(0,0,0,.6);
					}
				}
				@media (max-width: 1400px){
					padding: 0 15px;
				}
			}
		}
		.imagem{
			flex: 1;
		}
	}
</style>